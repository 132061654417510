function Advantage({ text }) {
    const className = text.length > 100 ? "flex items-start row-span-2" : "flex items-start";
    return (
    <div className={className}>
        <div className="h-6 w-6 mr-4">
            <svg className="text-saccent" fill="saccent" stroke="none" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" fill="#6D8C8F" strokeWidth="2" d="M23.4502 0.694526C23.2915 0.474454 23.1026 0.299778 22.8945 0.180575C22.6864 0.0613712 22.4632 0 22.2378 0C22.0123 0 21.7891 0.0613712 21.581 0.180575C21.3729 0.299778 21.184 0.474454 21.0253 0.694526L8.30279 18.2104L2.95763 10.8378C2.7928 10.6188 2.59822 10.4467 2.385 10.3312C2.17179 10.2156 1.94411 10.159 1.71497 10.1644C1.48582 10.1699 1.2597 10.2373 1.04952 10.3629C0.839339 10.4885 0.649207 10.6698 0.489982 10.8965C0.330757 11.1231 0.205558 11.3906 0.121531 11.6838C0.0375047 11.9769 -0.00370326 12.29 0.000261141 12.605C0.00422555 12.9201 0.0532845 13.231 0.144636 13.5199C0.235988 13.8089 0.367844 14.0704 0.532675 14.2893L7.09031 23.3055C7.24906 23.5255 7.43794 23.7002 7.64604 23.8194C7.85414 23.9386 8.07735 24 8.30279 24C8.52822 24 8.75143 23.9386 8.95953 23.8194C9.16763 23.7002 9.35651 23.5255 9.51526 23.3055L23.4502 4.14604C23.6236 3.92617 23.7619 3.65932 23.8565 3.3623C23.9511 3.06529 24 2.74454 24 2.42028C24 2.09602 23.9511 1.77528 23.8565 1.47826C23.7619 1.18125 23.6236 0.914395 23.4502 0.694526Z"></path>
            </svg>
        </div>
        <div>
            {text}
        </div>
    </div>
    );
}

function Advantages() {
    return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <Advantage text={"Работа в ИТ-компании, имеющей аккредитацию Минцифры"} />
        <Advantage text={"Команда профессионалов мирового уровня"} />
        <Advantage text={"Возможность работать дистанционно"} />
        <Advantage text={"Корпоративные мероприятия (праздники, хакатоны и другие интересные активности)"} />
        <Advantage text={"Достойный уровень заработной платы"} />
        <Advantage text={"Участие в профессиональных конференциях и тренингах"} />
        <Advantage text={"Индексация заработной платы и премирование"} />
        <Advantage text={"ДМС (включает стоматологию, скорую помощь, госпитализацию, поликлиническое обслуживание, вызов врача на дом) + НС (страхование от несчастных случаев, критических заболеваний)"} />
        <Advantage text={"Комфортный офис, рядом с офисом стадион, бассейн, парковая зона"} />
    </div>
    );
  }
  
  export default Advantages
  