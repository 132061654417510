function TextBlock({id, header, children, className, contentClassname, headerClassname}) {
  return <div id={id ?? ''} className={`flex flex-col items-center justify-center pb-[9.375rem] ${className ?? ''}`}>
    <div className={`w-full spadding ${contentClassname}`}>
      {header ? <h1 className={`w-full text-center mt-4 pt-4 pb-4 font-header-h2 mt-[5.2rem] mb-[5.6rem] ${headerClassname}`}>{header}</h1> : ''}
      {children}
    </div>
  </div>
}

export default TextBlock
