import TextBlock from "./TextBlock";

function LightBlock({id, header, children, className}) {
  return <TextBlock
    id={id}
    header={header}
    className={`bg-sgray pt-[6.5rem] ${className ?? ''}`}
  >
    {children}
  </TextBlock>
}

export default LightBlock
