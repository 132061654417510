import ExpertiseBlock from "../components/ExpertiseBlock";

function Expertise() {
  return (
    <div>
      <ExpertiseBlock
        header="Разработка ПО и интеграционных решений"
        items={[
          'многофункциональные web-приложения',
          'мобильные приложения',
          'высоконагруженные распределенные отказоустойчивые решения',
          'решения на основе сервисно-ориентированной архитектуры и интеграционных шин',
          'системы автоматизации бизнес-процессов'
        ]}
      />

      <ExpertiseBlock
        header="Аутсорсинг и консультационные услуги в сфере ИТ"
        items={[
          'IT-аутсорсинг разработки и тестирования программного обеспечения.',
          'экспертные консультации в области ИТ-архитектуры, бизнес-анализа, Agile-методологии, а также разработки, автоматического и нагрузочного тестирования программного обеспечения.'
        ]}
      />

      <ExpertiseBlock
        header="Разработка решений на базе AI"
        className=""
        items={[
          'обработка изображений',
          'генерация текста',
          'создание виртуальных ассистентов'
        ]}
      />
    </div>
  );
}

export default Expertise;
