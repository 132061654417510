import {useState} from 'react'

function ExpertiseBlock({items, header, className}) {
  const [isOpen, setIsOpen] = useState(false)
  const toggleOpen = () => setIsOpen(!isOpen)
  return <div className={`expertise-block  ${className ?? 'mb-14'}`}>
    <h2 className="font-header-h3 pb-4 mb-4 border-b border-sblack flex flex-grow justify-between items-center">
      <div className="cursor-pointer" onClick={toggleOpen}>{header}</div>
      <div>
        <button className="text-[3.75rem] transition-all"
                onClick={toggleOpen}><div className={`plus${isOpen ? ' open' : ''}`}></div></button>
      </div>
    </h2>
    <div className={`flex text-expand ${isOpen ? "visible" : ""}  align-left justify-start`}>
      <ul className="">
        {items.map(item => <li className="leading-7 font-text-regular">• {item}</li>)}
      </ul>
    </div>
  </div>
}

export default ExpertiseBlock
