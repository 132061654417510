import { useState } from 'react';
import { toast } from 'react-toastify';
import { isEmailValid, isValidURL } from '../helpers/validate';

const Msg = () => (
    <div className="flex">
        <div className="logo-mini" />
        Резюме отправлено.<br />Скоро мы свяжемся с Вами.
    </div>
);

function CVForm({ close }) {
    const [errors, setErrors] = useState([]);
    const [data, setData] = useState({});

    const showToast = () => {
        toast(<Msg />, { toastId: 'msg-toast' });
    }

    const verifyData = () => {
        const errors = [];
        const fields = ["name", "email", "position", "link"];
        fields.forEach(field => {
            const fieldValue = data[field];

            if (!(fieldValue && fieldValue.trim())) {
                errors.push(field);
            }
        });

        const email = data['email'];
        if (email && !isEmailValid(email)) {
            errors.push('email');
        }

        const link = data['link'];
        if (link && !isValidURL(link)) {
            errors.push('link');
        }

        const agreement = data["agreement"];
        if (!agreement) {
            errors.push('agreement');
        }

        return errors;
    }

    const handleChangeInput = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({
            ...prevData,
            [name]: value
        }));
        setErrors(errors.filter(errorField => errorField !== name))
    };

    const handleChangeCheckbox = (e) => {
        const { name, checked } = e.target;
        setData((prevData) => ({
            ...prevData,
            [name]: checked
        }));
        setErrors(errors.filter(errorField => errorField !== name))
    };

    const handleSendData = async () => {
        const errors = verifyData();
        if (errors.length > 0) {
            setErrors(errors);
        } else {
            const response = await fetch('/api/resume', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            showToast();

            close();

            if (!response.ok) {
                console.error('Failed to send resume:', response.statusText);
            }
        }
    }

    return (
        <div className="content-center font-header-h2 md:m-10 cursor-pointer px-4 sm:px-14 py-8 relative">
            <div className="flex flex-col items-center justify-center">
                <h2 className="font-header-h2 text-center text-swhite mb-8 mt-10" id="contact">Присоединяйтесь к нашей команде!</h2>

                <div className="w-full md:w-2/3 font-text-regular">
                    <input
                        name="name"
                        type="text"
                        placeholder="ФИО*"
                        className={`bg-swhite h-16 bg-opacity-10 border border-swhite border-[1px] rounded-[0.5rem] p-2 mb-4 w-full ${errors.includes("name") ? 'placeholder-red-500 text-red-500' : 'text-swhite'}`}
                        onChange={handleChangeInput}
                    />
                    <input
                        name="email"
                        type="text"
                        placeholder="E-mail*"
                        className={`bg-swhite h-16 bg-opacity-10 border border-swhite border-[1px] rounded-[0.5rem] p-2 mb-4 w-full ${errors.includes("email") ? 'placeholder-red-500 text-red-500' : 'text-swhite'}`}
                        onChange={handleChangeInput}
                    />
                    <input
                        name="position"
                        type="text"
                        placeholder="Интересующая должность*"
                        className={`bg-swhite h-16 bg-opacity-10 border border-swhite border-[1px] rounded-[0.5rem] p-2 mb-4 w-full ${errors.includes("position") ? 'placeholder-red-500 text-red-500' : 'text-swhite'}`}
                        onChange={handleChangeInput}
                    />
                    <input
                        name="link"
                        type="text"
                        placeholder="Ссылка на резюме*"
                        className={`bg-swhite h-16 bg-opacity-10 border border-swhite border-[1px] rounded-[0.5rem] p-2 mb-4 w-full ${errors.includes("link") ? 'placeholder-red-500 text-red-500' : 'text-swhite'}`}
                        onChange={handleChangeInput}
                    />
                </div>

                <div className="flex mb-4 w-full md:w-2/3 justify-end font-text-small text-swhite">
                    * поля, обязательные для заполнения
                </div>

                <div className="flex mb-4 w-full md:w-2/3 items-start justify-end">
                    <input name="agreement" type="checkbox" id="consent" className="mt-1 mr-2" onChange={handleChangeCheckbox} />
                    <label htmlFor="consent" className={`xs:w-52 md:w-fit text-right font-text-small ${errors.includes("agreement") ? 'text-red-500' : 'text-swhite'}`}>
                        Я даю <a className='text-regular underline' target="_blank" href='/doc/agreement.pdf'>согласие</a> на обработку моих персональных данных.
                    </label>
                </div>

                <div className="w-full md:w-2/3 flex justify-end ">
                    <button className="cursor-pointer font-text-small uppercase px-6 py-2 mt-6 mb-2 bg-swhite rounded-full text-sblack
          transition-all
          hover:bg-saccent" onClick={handleSendData}>
                        Отправить
                    </button>
                </div>
            </div>

        </div>
    );
}

export default CVForm;