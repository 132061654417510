import TextSections from "../components/TextSections";
import LightBlock from "../components/LightBlock";
import DarkBlock from "../components/DarkBlock";
import Separator from "../components/Separator";
import Values from "../components/Values";
import Advantages from "../components/Advantages";
import Employment from "../components/Employment";
import ImageSlider from "../components/ImageSlider";

const sections1 = [
  `Мы работаем по Agile-методологии, используем микросервисную архитектуру и современный технологический стек.`
];

const sections2 = [
  `Разнообразие проектов позволяет каждому развивать свои профессиональные навыки и реализовывать свой творческий потенциал.`
];


function Career() {
  const handleHHClick = () => {
    window.open('https://korolev.hh.ru/employer/9994260', '_blank');
  };

  return (
    <div id="career">
      <div className="bg-saccent">
        <Separator className="rounded-t-3xl bg-sgray" />
      </div>

      <LightBlock className="pt-8 md:pt-[6.5rem]">
        <div className="text-center pb-[3.125rem] md:pb-20">
          <h2>Карьера</h2>
        </div>

        <div>
          <div className="flex flex-col md:flex-row gap-[8rem] justify-center items-center md:items-start">
            <img className="rounded-[32px]" src="/img/career.png" alt="Career-pic" />
            <div className="md:max-w-[700px] text-left md:text-left">
              <div className="mb-14">
                <TextSections sections={sections1} />
                <TextSections sections={sections2} />
              </div>
              <div className="flex items-center md:flex-row">
                <div className="font-text-regular-bold">
                  Актуальные вакансии на
                </div>

                <button className="hh-button hover:bg-sblack transition-all rounded-[2rem] border-[1px] border-sblack" onClick={handleHHClick}>
                  <img className="max-w-[25px]" src="/img/hh.ru.svg" alt="hh-logo" />
                </button>
              </div>

            </div>
          </div>

        </div>


      </LightBlock>

      <div className="bg-sgray">
        <Separator className="rounded-t-3xl bg-sblack" />
      </div>

      <DarkBlock className="pt-8 md:pt-[6.5rem]">
        <div className="text-center pb-[3.125rem] md:pb-20">
          <h2>Наши ценности</h2>
        </div>
        <Values />
      </DarkBlock>

      <Separator className="rounded-t-3xl bg-sgray" />

      <LightBlock className="pt-8 md:pt-[6.5rem]">
        <div className="text-center pb-[3.125rem] md:pb-20">
          <h2>Наши преимущества</h2>
        </div>
        <div class="flex justify-center items-center">
          <div className="max-w-[1296px]">
            <Advantages />
            <div className="hidden lg:flex md:flex justify-center" >
              <img className="w-11/12 mt-14 p-4" src="/img/collage.png" alt="collage-pic" />
            </div>
            <div className="w-full lg:hidden md:hidden mt-14">
              <ImageSlider images={["/img/collage_mob1.png", "/img/collage_mob2.png"]} />
            </div>
          </div>
        </div>
      </LightBlock>

      <div className="bg-sgray">
        <Separator className="rounded-t-3xl bg-saccent" />
      </div>
      <LightBlock className="bg-saccent pt-8 md:pt-[6.5rem]">
        <div className="text-center pb-[3.125rem] md:pb-20">
          <h2>Процесс трудоустройства</h2>
        </div>

        <div class="flex justify-center items-center">
          <div className="max-w-[1296px]">
            <Employment />
          </div>
        </div>

      </LightBlock>
    </div>
  );
}

export default Career;
