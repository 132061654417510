import AboutCompany from './AboutCompany'

function AboutUs() {
  return (
    <>
      <AboutCompany/>
    </>
  );
}

export default AboutUs;
