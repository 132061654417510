function MenuToggle({toggleMenu, menuVisible}) {
  return <div className="sm:hidden mr-5">
    <button className="outline-none menu-button justify-end" onClick={toggleMenu}>
      <div className={`button ${menuVisible? '-menu-open' : ''}`}>
        <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g>
            <line x1="0" y1="17" x2="40" y2="17" stroke-width="1"/>
            <line x1="0" y1="31" x2="40" y2="31" stroke-width="1"/>
          </g>

          <g>
            <line x1="0" y1="20" x2="40" y2="20" stroke-width="1"/>
            <line x1="0" y1="20" x2="40" y2="20" stroke-width="1"/>
          </g>
        </svg>
      </div>
    </button>
  </div>
}

export default MenuToggle
