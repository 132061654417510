import TextBlock from "./TextBlock";

function DarkBlock({id, header, children, className}) {
  return <TextBlock
    id={id}
    header={header}
    className={`bg-sblack  pt-[6.5rem] ${className ?? ''}`}
    contentClassname="text-sgray"
  >
    {children}
  </TextBlock>
}

export default DarkBlock
