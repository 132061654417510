const menu = [
  {
    title: 'УСЛУГИ',
    route: 'services'
  },
  {
    title: 'О НАС',
    route: 'about'
  },
  {
    title: 'КАРЬЕРА',
    route: 'career'
  },
  {
    title: 'КОНТАКТЫ',
    route: 'contacts'
  }
]

export default menu
