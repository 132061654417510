const data = [
  {
    header: 'Планирование',
    image: 'planning.png',
    text: 'Определяем техническое задание. Cогласовываем этапы и сроки.'
  },
  {
    header: 'Разработка и тестирование',
    image: 'testing.png',
    text: 'Итеративный подход к разработке. Многоуровневое тестирование.'
  },
  {
    header: 'Поддержка',
    image: 'support.png',
    text: 'Поддерживаем продукт после его запуска в эксплуатацию.'
  }
]

function WorkProcess({ header, image, text }) {
  return (<div className="w-[326px] h-[330px] md:w-[337px] md:h-[330px] lg:w-[296px] lg:h-[296px] xl:w-[330px] xl:h-[300px] 2xl:w-[360px] 2xl:h-[330px] rounded-[20px] 
  border border-saccent border-opacity-30
   flex flex-col
  align-center justify-center items-center text-center">
    <div>
      <img className="mx-auto" src={`/img/${image}`} alt={image} />
    </div>
    <div className="font-header-h3 my-4 mx-2">{header}</div>
    <div className="font-text-regular mx-2">{text}</div>
  </div>);
}

function WorkProcesses() {
  return (<div className="flex flex-col lg:flex-row gap-5 lg:gap-6 xl:gap-16 2xl:gap-20 justify-center  align-center items-center text-center">
    {data.map(({ header, image, text }) =>
      <WorkProcess header={header} image={image} text={text} />)}
  </div>
  );
}

export default WorkProcesses
