import Advantage from "../components/Advantage";

function Title({showProjectForm}) {
  return (
    <div
      className="bg-[url('../../public/img/title_4.jpg')] [background-position-y:-0px] bg-cover bg-center bg-no-repeat title text-swhite">
      <div className="md:h-screen flex flex-grow justify-center">
        <div className="md:h-screen flex flex-col items-center justify-around">
          <div className="pt-12 px-4 text-center">
            <h1 className="text-center mb-[0rem] pb-[0.75rem] ">
              Мы разрабатываем<br/>
              IT-решения для Ваших задач
            </h1>
            <div className="font-title-regular">Спринтерра - аккредитованная <span className="whitespace-nowrap">ИТ-компания</span></div>
            <div className="font-title-regular">Мы создаем продукты различной сложности и направленности</div>
          </div>

          <div className="md:hidden mt-20 mb-10 right-[5rem] rounded-[2rem] border-[1px] border-1 border-saccent h-[40px] w-[170px] text-center
           p-[12px] text-xs text-white bg-sblack
           hover:bg-saccent cursor-pointer"
            onClick={showProjectForm}
          >
            ОБСУДИТЬ ПРОЕКТ
          </div>

          <div className="w-full">
              <div className="grid grid-container grid-cols-1 md:grid-cols-3">
                <Advantage
                  header="20+ лет"
                >
                  опыт разработки решений<br/>в ИТ-сфере
                </Advantage>
                <Advantage
                  header="Agile-культура"
                >
                  создаем продукты быстро<br/>и эффективно
                </Advantage>
                <Advantage
                  header="24 / 7 / 365"
                >
                  поддержка работы наших<br/>решений
                </Advantage>
            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Title;
