import { useState } from "react";
import { toast } from 'react-toastify';
import { useKeyStroke } from '@react-hooks-library/core'
import { isEmailValid } from "../helpers/validate";

const mandatoryFields = {
  name: '',
  email: '',
  phone: '',
  company: '',
}

const labels = {
  name: 'Имя*',
  email: 'E-Mail*',
  phone: 'Телефон*',
  company: 'Компания*',
  comment: 'Комментарий',
}

const Msg = () => (
  <div className="flex">
    <div className="logo-mini" />
    Сообщение отправлено.<br />Скоро мы свяжемся с Вами.
  </div>
);

let pressed = ''
let isDevMode = false
const checkIsDevMode = () => {
  if (pressed === 'cscscs') {
    console.log('dev mode');
    isDevMode = true
  }
}

function ContactForm({ successCallback }) {
  useKeyStroke('Control', () => {
    pressed += 'c'
    checkIsDevMode()
    if (isDevMode) {
      toast(<Msg />, { toastId: 'msg-toast' })
    }
  })
  useKeyStroke('Shift', () => {
    pressed += 's'
    checkIsDevMode()
    if (isDevMode) {
      toast(<Msg />, { toastId: 'msg-toast' })
    }
  })

  const [errors, setErrors] = useState([])
  const [formData, setFormData] = useState({
    ...mandatoryFields,
    comment: ''
  });

  const showToast = () => {
    toast(<Msg />, { toastId: 'msg-toast' });
  }

  const sendData = async () => {
    try {
      if (isDevMode > 1) {
        showToast()
      } else {
        const errors = []
        Object.keys(mandatoryFields).forEach(field => {
          if (!formData[field].trim()) {
            errors.push(field)
          }
        })
        if (formData['email'].trim() && !isEmailValid(formData['email'])) {
          errors.push('emailFormat')
        }
        if (errors.length) {
          setErrors(errors)
        } else {
          const response = await fetch('/api/contact', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ subject: 'feedback', ...formData })
          });

          showToast()

          if (successCallback) {
            successCallback()
          }

          setFormData({
            name: '',
            email: '',
            phone: '',
            company: '',
            comment: ''
          });


          if (!response.ok) {
            console.error('Failed to send data:', response.statusText);
          }
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendData();
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
    setErrors(errors.filter(errorField => errorField !== name))
  };


  return <div className="p-[4rem] gap-14 font-text-regular">
    <form onSubmit={handleSubmit}>
      {Object.keys(mandatoryFields).map(field => <div>
        {errors.includes(field)}
        <input key={field} type="text" placeholder={labels[field]}
          name={field}
          id={field}
          value={formData[field]}
          className={`w-full lg:leading-8 bg-swhite border-b-2 outline-none mb-6 ${errors.includes(field) ? 'placeholder-red-500 text-red-500' : ''}`}
          onChange={handleChange} />
      </div>
      )}
      <div>
        <textarea name="comment" placeholder="Комментарий" rows="1"
          onChange={handleChange}
          value={formData.comment}
          className=" w-full bg-swhite border-b-2 outline-none mb-6"></textarea>
      </div>
      <div
        className={`text-[#48505E] w-full text-right ${errors.filter(error => error !== 'emailFormat').length && 'text-red-500'}`}>
        * поля, обязательные для заполнения
        <div className='text-red-500 mb-2'>
          {errors.includes('emailFormat') ? 'Пожалуйста, введите корректный E-mail' : ''}
        </div>
      </div>
      <div className="w-full text-right ">
        <input type="submit"
          className="cursor-pointer bg-sblack font-text-small uppercase px-4 py-2 mt-2 mb-2 rounded-full text-swhite
                  transition-all
                  hover:bg-saccent
                  focus:bg-saccent
                  "
          value="Отправить" />
      </div>
      <div className="w-full text-right text-[#48505E] ">
        Нажимая кнопку, Вы соглашаетесь с <a className='text-regular pt-2 underline' target="_blank" href='/doc/privat_policy.pdf'>Политикой</a> обработки персональных данных в ООО "Спринтерра".
      </div>
    </form>
  </div>
}

export default ContactForm
