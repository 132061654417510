import Title from './Title'
import About from './About'

function Home({showProjectForm}) {
  return (
    <>
      <Title showProjectForm={showProjectForm}/>
      <About showProjectForm={showProjectForm}/>
    </>
  );
}

export default Home;
