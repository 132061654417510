function Project({header, img, description = "123"}) {
  return <div className="group project overflow-hidden relative     
    w-[326px]
    max-h-[346px]
    lg:w-[376px]
    lg:max-h-[399px]
    2xl:w-[416px]
    2xl:max-h-[416px]
" >
    <div className="text-data z-10 transform duration-200 absolute
    project__text
    group-hover:mt-[0] text-swhite p-4">
      <h3 className="font-header-h3 text-start w-full">{header}</h3>
      <div className="transform duration-0 py-4 opacity-0 group-hover:opacity-100 group-hover:block">{description}</div>
    </div>


    <div className="flex flex-col flex-grow bg-sblack
    justify-center rounded-3xl overflow-hidden">
      <img src={img} className="group-hover:opacity-50 project__image"  alt="Project Item"/>
    </div>
  </div>
}

function Projects() {
  return <div className="flex justify-center">
    <div className="grid
    project__grid
    gap-5
    md:gap-6
    items-center
    [justify-items:center]
    justify-center">
      <Project
        img="/img/proj/1.png"
        header="Цифровая платформа для публикации статей в научных журналах"
        description={<ul className="list-disc p-4">
          <li>Многофункциональное web-приложение</li>
          <li> Решение на основе сервисно-ориентированной архитектуры</li>
          <li>Включает подсистему публикации статей в открытом доступе</li>
        </ul>}
      />
      <Project
        header="Система управления сервисами привлечения клиентов"
        img="/img/proj/2.png"
        description={
          <ul className="list-disc p-4">
            <li>Многофункциональное web-приложение</li>
            <li>Интеграционное решение</li>
            <li>Продукт с использованием технологий искусственного интеллекта</li>
          </ul>
        }
      />
      <Project
        img="/img/proj/3.png"
        header="Система цифровой обработки и хранения научных статей"
        description={<ul className="list-disc p-4">
          <li>Многофункциональное web-приложение</li>
          <li>Решение на основе сервисно-ориентированной архитектуры</li>
        </ul>}
      />
      <Project
        header="E-learning: система для высших учебных заведений и колледжей"
        img="/img/proj/4.png"
        description={
          <ul className="list-disc p-4">
            <li>Многофункциональное web-приложение</li>
            <li>Предоставляет более 50 различных типов вопросов в тест-банках для создания домашних заданий, тестов, проверочных
              работ, экзаменов
            </li>
            <li>Высоконагруженное распределенное отказоустойчивое решение</li>
            <li>Интеграционное решение</li>
          </ul>
        }
      />
      <Project
        header="E-learning: корпоративная LMS с AI-помощником"
        img="/img/proj/5.png"
        description={<ul className="list-disc p-4">
          <li>Многофункциональное web-приложение</li>
          <li>Интеграционное решение</li>
          <li>Продукт с использованием технологий искусственного интеллекта</li>
        </ul>}
      />
      <Project
        header="HRM система"
        img="/img/proj/6.png"
        description={
          <ul className="list-disc p-4">
            <li>Многофункциональное web-приложение</li>
            <li>Интеграционное решение</li>
            <li>Продукт с использованием технологий искусственного интеллекта</li>
          </ul>
        }
      />
    </div>
  </div>
}

export default Projects
