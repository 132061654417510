import './styles/index.scss';
import Layout from "./Layout"
import Home from "./pages/Home"
import AboutUs from "./pages/AboutUs";
import Career from "./pages/Career";
import Contacts from "./pages/Contacts";

function App() {
  return (
    <Layout>
      <Home/>
      <AboutUs/>
      <Career/>
      <Contacts/>
    </Layout>
  );
}

export default App;
