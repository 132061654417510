import LightBlock from "../components/LightBlock";
import Separator from "../components/Separator";

function Contacts() {
  return (
    <div id="contacts">
      <div className="bg-saccent">
        <Separator className="rounded-t-3xl bg-sgray" />
      </div>

      <LightBlock className="pt-8 md:pt-[6.5rem]">
      <div class="flex justify-center items-center">
          <div className="max-w-[1296px]">

            <div className="w-full justify-center text-sblack">
                <div className="text-center pb-[3.125rem] md:pb-20">
                    <h2>Мы открыты к сотрудничеству</h2>
                </div>

                <div className="text-center font-text-regular space-y-2.5">
                    <div>+7 (498) 719-0-917</div>
                    <div>info@sprinterra.ru</div>
                    <div>Московская обл., г. Королёв,  ул. Дзержинского, д. 29, офис 5</div>
                </div>

                <Separator className="h-[70px]"/>

                <div className="flex w-full justify-center">
                  <div className="overflow-hidden rounded-[2rem] md:underlay">
                    <iframe className="w-[310px] h-[220px] 
                    sm:w-[405px] sm:h-[288px]
                    md:w-[456px] md:h-[330px]
                    xl:w-[546px] xl:h-[396px]
                    2xl:w-[636px] 2xl:h-[462px]"
                    title="contacts map" src="https://yandex.ru/map-widget/v1/?um=constructor%3Acede5f0615a8a8147d2babb6d09f8fe8bd02d587fe929f46a63dca94e0984bce&amp;source=constructor" frameborder="0"></iframe>
                  </div>
                </div>

            </div>
          </div>
        </div>
      </LightBlock>

    </div>
  );
}

export default Contacts;


